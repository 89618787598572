<template>
  <v-container id="proximidade-filtros">
    <v-row>
      <v-col class="pb-0">
        <v-btn
          :loading="carregandoGerarRelatorio"
          :disabled="carregandoGruposPontosCaptura || carregandoPontosCaptura"
          color="info"
          @click="gerarAnalise()"
        >
          <v-icon
            class="mr-2"
            v-text="'mdi-car-3-plus'"
          />
          Gerar análise
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          class="mt-0"
          :loading="carregandoGruposPontosCaptura || carregandoPontosCaptura"
        >
          <v-card-title class="caption">
            <b>Adicionar Passagem</b>
          </v-card-title>
          <v-card-text>
            <v-treeview
              v-model="treeviewSelecionados"
              class="body-1"
              selectable
              dense
              item-text="descricao"
              :items="treeview"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="pl-1"
      >
        <v-card class="mt-0">
          <v-card-title class="caption">
            <b>Lista de passagens</b>
          </v-card-title>
          <v-card-text>
            <div class="body-2">
              <p
                v-for="leitura in $store.state.relatorioProximidade.leiturasSelecionadas" :key="leitura.leitura_id"
                class="mb-0"
              >
                {{ leitura.data_registro | data('DD/MM/YYYY HH:mm:ss') }} - {{ leitura.placa }}
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="pl-1"
      >
        <v-card class="mt-0">
          <v-card-text class="pa-0">
            <v-sheet
              color="pa-3 pt-1 mb-2 grey lighten-3"
            >
              <p class="subtitle-2 text-center mb-2">
                Intervalo de veículos
              </p>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="filtros.limiteMinutosInferior"
                    dense
                    outlined
                    autocomplete="off"
                    hide-details="auto"
                    type="number"
                    label="Inicial"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="filtros.limiteMinutosSuperior"
                    dense
                    outlined
                    autocomplete="off"
                    hide-details="auto"
                    type="number"
                    label="Final"
                  />
                </v-col>
              </v-row>
            </v-sheet>
            <v-sheet
              color="pa-3 pt-1 mb-2 grey lighten-3"
            >
              <p class="subtitle-2 mb-2 text-center">
                Período predominante
              </p>
              <v-row no-gutters>
                <v-col
                  cols="6"
                  v-for="periodo in periodos"
                  :key="periodo.id"
                >
                  <v-checkbox
                    v-model="filtros.periodoPredominante"
                    class="mt-0"
                    hide-details
                    :value="periodo.id"
                  >
                    <template v-slot:label>
                      <span class="body-2">{{ periodo.descricao }}</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-sheet>

            <v-sheet color="pa-3 pt-1 grey lighten-3">
              <p class="subtitle-2 mb-2 text-center">
                Perfil do veículo
              </p>
              <v-row>
                <v-col>
                  <v-select
                    v-model="filtros.tiposVeiculos"
                    :items="tiposVeiculos"
                    :loading="carregandoTiposVeiculos"
                    item-text="descricao"
                    item-value="id"
                    label="Tipo de Veículo"
                    hide-details
                    multiple
                    outlined
                    dense
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip
                        v-if="index < 2"
                        small
                      >
                        {{ item.descricao }}
                      </v-chip>
                      <span
                        v-if="index === 2"
                        class="grey--text text-caption"
                      >
                        (+{{ filtros.tiposVeiculos.length - 2 }})
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="pt-0">
                  <v-checkbox
                    v-model="filtros.veiculosSemPlaca"
                    class="mt-0"
                    hide-details
                  >
                    <template v-slot:label>
                      <span class="body-2">Veículos sem placa</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="pt-0">
                  <v-checkbox
                    v-model="filtros.veiculosSemCoincidencia"
                    class="mt-0"
                    hide-details
                  >
                    <template v-slot:label>
                      <span class="body-2">Veículos sem coincidência</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex'
  import apiRelatorios from '@/api/relatorios'
  import apiPontosCaptura from '@/api/pontos-captura'
  import apiGruposPontosCaptura from '@/api/grupos-pontos-captura'
  import apiTiposVeiculos from '@/api/tipos-veiculos'

  export default {

    data () {
      return {
        carregandoGerarRelatorio: false,
        carregandoGruposPontosCaptura: false,
        carregandoPontosCaptura: false,
        carregandoTiposVeiculos: false,
        treeviewSelecionados: [],
        pontosCaptura: [],
        gruposPontosCaptura: [],
        tiposVeiculos: [],
        periodos: [
          { id: 1, descricao: 'Madrugada' },
          { id: 2, descricao: 'Manhã' },
          { id: 3, descricao: 'Tarde' },
          { id: 4, descricao: 'Noite' },
        ],
        filtros: {
          limiteMinutosInferior: 3,
          limiteMinutosSuperior: 3,
          periodoPredominante: [],
          tiposVeiculos: [],
          veiculosSemPlaca: true,
          veiculosSemCoincidencia: true,
        }
      }
    },

    computed: {
      treeview () {
        return [
          {
            id: 99999,
            descricao: 'Grupos',
            children: this.gruposPontosCaptura,
          },
          {
            id: 99998,
            descricao: 'Pontos de Captura',
            children: this.pontosCaptura,
          },
        ]
      },
    },

    beforeCreate () {
      if (this.$store.state.relatorioProximidade.leiturasSelecionadas.length <= 0) {
        this.$snackbar.mostrar({ mensagem: 'Selecione pelo menos uma passagem' })
        return this.$router.push('/')
      }
    },

    mounted () {
      this.buscarPontosCaptura()
      this.buscarGruposPontosCaptura()
      this.buscarTiposVeiculos()
    },

    methods: {
      ...mapMutations({
        setProximidadeResultado: 'relatorioProximidade/SET_RESULTADO',
        setProximidadeFiltros: 'relatorioProximidade/SET_FILTROS',
      }),

      async buscarGruposPontosCaptura () {
        try {
          this.carregandoGruposPontosCaptura = true
          const resposta = await apiGruposPontosCaptura.listar({ por_pagina: 1000 })
          this.gruposPontosCaptura = resposta.data.grupos_ponto_captura.map((i) => {
            const id = `g-${i.id}`
            this.treeviewSelecionados.push(id)

            return { id, descricao: i.descricao }
          })
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'warning', mensagem: 'Erro ao buscar os grupos dos pontos de captura' })
        } finally {
          this.carregandoGruposPontosCaptura = false
        }
      },

      async buscarPontosCaptura () {
        try {
          this.carregandoPontosCaptura = true
          const resposta = await apiPontosCaptura.listar({ por_pagina: 1000 })
          this.pontosCaptura = resposta.data.data.map((i) => {
            const id = `p-${i.id}`
            this.treeviewSelecionados.push(id)

            return { id, descricao: i.descricao }
          })
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'warning', mensagem: 'Erro ao buscar os pontos de captura' })
        } finally {
          this.carregandoPontosCaptura = false
        }
      },

      async buscarTiposVeiculos () {
        try {
          this.carregandoTiposVeiculos = true
          const resposta = await apiTiposVeiculos.listar({ por_pagina: 1000 })
          this.tiposVeiculos = resposta.data.tipos_veiculo

          this.filtros.tiposVeiculos = this.tiposVeiculos.map((t) => t.id)
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'warning', mensagem: 'Erro ao buscar os tipos de veículos' })
        } finally {
          this.carregandoTiposVeiculos = false
        }
      },

      async gerarAnalise () {
        try {
          this.carregandoGerarRelatorio = true

          const dados = { ...this.filtros }
          dados.leituras = this.$store.state.relatorioProximidade.leiturasSelecionadas.map((l) => l.leitura_id)

          const gruposSelecionados = this.treeviewSelecionados.filter((e) => e.split('-')[0] === 'g')
          dados.gruposPontosDeCaptura = gruposSelecionados.map((g) => parseInt(g.split('-')[1]))

          const pontosSelecionados = this.treeviewSelecionados.filter((e) => e.split('-')[0] === 'p')
          dados.pontosDeCaptura = pontosSelecionados.map((p) => parseInt(p.split('-')[1]))

          const resposta = await apiRelatorios.analiseProximidade(dados)

          this.setProximidadeResultado(resposta.data)
          this.setProximidadeFiltros(dados)

          this.$router.push('/relatorios/proximidade/analise')
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoGerarRelatorio = false
        }
      },

    },

  }
</script>

<style lang="sass">
  #proximidade-filtros
    .v-text-field input
      padding: 5px 0 5px

    .v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot
      font-size: 14px

    .v-input__control > .v-input__slot
      min-height: 0px
</style>
