import axios from '@/plugins/axios'

export default {

  async listar (dados) {
    const parametros = new URLSearchParams(dados).toString()
    return await axios.get(`pontos-captura?${parametros}`)
  },

  async buscar (id) {
    return await axios.get(`pontos-captura/${id}`)
  },

  async inserir (dados) {
    return await axios.post('pontos-captura', dados)
  },

  async atualizar (id, dados) {
    return await axios.put(`pontos-captura/${id}`, dados)
  },

  async deletar (id) {
    return await axios.delete(`pontos-captura/${id}`)
  },

  async listarCameras (id, dados) {
    const parametros = new URLSearchParams(dados).toString()
    return await axios.get(`pontos-captura/${id}/cameras?${parametros}`)
  },

  async camerasPorPontoCaptura () {
    return await axios.get(`status/cameras-por-ponto-captura`)
  },
}
